.btn_wrapper button {
  min-width: 140px;
  height: 48px;
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 20px;
  transition: 300ms;
  cursor:pointer;
}
.btn_wrapper {
  margin-top: 30px;
}
.btn_secondary {
  margin-right: 20px;
}
