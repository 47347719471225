.inputTexte_input  {
  border-radius: 3px;
  border: 1px solid #5B6E8C;
  background: #3E5578;
  height: 43px;
  padding-left : 14px;
  padding-right : 14px;
  color: white;
  width: 220px;
}

.inputTexte_input:focus {
  outline: none;
}


.inputTexte_textarea  {
  resize:none;
  border-radius: 3px;
  border: 1px solid #5B6E8C;
  background: #3E5578;
  height: 95px;
  padding : 14px;
  color: white;
  width: 280px;
}

@media (max-width: 600px) {
  .inputTexte_input  {
    width: calc(100% - 75px);
  }

  .inputTexte_input.withImage  {
    width: calc(100% - 30px) !important;
  }

  .inputTexte_textarea  {
    width: calc(100% - 75px);
  }

  .inputTexte_textarea.withImage  {
    width: calc(100% - 30px) !important;
  }
}
