.format_text {
  display: flex;
  align-items: center;
}
.format_mask {
  height: 100%;
  min-width: 50%;
  width: 50%;
}

.format_mask.maskCover {
  background-size: cover;
  mask-image: url(../../../img/mask-fill.svg);
  -webkit-mask-image: url(../../../img/mask-fill.svg);
}

@media (max-width: 600px) {
  .format_mask.maskCover {
    overflow: hidden;
  }
}
.format_mask > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.format_mask_content {
  position: relative;
  top: -50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px 0;
}

.format_mask_content.mask_inline_image img {
  width: calc(100% - 40px);
  margin: 0 20px;
}
