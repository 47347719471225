.format_titre {
  display: flex;
  align-items: center;
}
.format_titre .format_mask {
  height: 100%;
  min-width: 40%;
  width: 40%;
  position: relative;
}


.format_mask > .title_bkgr {
  height: 100%;
  width: fit-content;
  position: absolute;
  object-fit: contain;

  z-index: 0;
  object-position: left;
}
.title_icon_wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
  z-index: 2;
  position: absolute;
}
img.title_icon {
  max-width: 100%;
  max-height: 100%;
}

.format_titre .format_wrapper {
  z-index: 1;
}
