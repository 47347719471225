.modalContentFormation textarea,
.modalContentFormation input[type='text'] {
  box-shadow: 0 0 10px #7d7d7d3d;
  border: solid 1px #8080806e;
}
.modalContentFormation button.mauticform-button {
  color: white;
  min-width: 140px;
  height: 38px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 20px;
}

.modalContentFormation input[type='radio'],
.modalContentFormation input[type='checkbox'] {
  width: 18px;
  display: initial;
  margin: 10px 10px 10px 0;
}
.modalContentFormation .mauticform-radiogrp-row {
  display: flex;
  align-items: center;
}
.modalContentFormation .mauticform-radiogrp-row > label {
  margin: 0;
}
