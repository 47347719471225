.feedback_donut .nombreQues span {
  font-size: 45px;
  color: white;
  border-radius: 100%;
  height: 77px;
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback_donut .nombreQues p {
  font-size: 16px;
  color: white;
  margin-top: 20px;
}
.feedback_donut .nombreQues {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: max-content;
  top: 50px;
}
.donutQuizz {
  animation: donutQuizz 2s both ease-in-out;
}
.donut79Mo {
  animation: donut79Mo 2s both ease-in-out;
}
.svg-donut {
  transform: scaleX(-1) rotate(-90deg);
  position: absolute;
}
.svg-donut. {
  display: block;
}

.feedback_donut p {
  margin-left: 15px;
  font-size: 20px;
}
.feedback_donut {
  display: flex;
  align-items: center;
  padding: 20px 45px;
}
.nombreQues span.quizzGodd_xs {
  font-size: 25px;
}

.diag_donut {
  display: flex;
  align-items: center;
}
.diag_donut h4 {
  padding: 0 0;
}
