@media (max-width: 860px) {
  .homepage html {
    font-family: 'Circular', sans-serif;
    font-weight: normal;
  }

  .homepage main {
    overflow: hidden;
  }
  .homepage h4.hook__title.background {
    display: none;
  }
  .homepage #titleLab {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .homepage .home__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .homepage img.header__logo {
    width: 60px;
  }
  .homepage nav {
    display: none !important;
  }
  .homepage header .nav__connexion {
    display: none;
  }
  .homepage .header__home {
    width: 100vw;
    height: 90vh;
    background-image: url('https://video.thelab.risinguparis.com/img/background_tower.png');
    background-repeat: no-repeat;
    background-size: 1440px 750px;
    background-position: bottom;
  }

  .homepage .home__nav {
    /* display: none; */
    z-index: 1000;
  }

  .homepage .header__logo {
    width: 4.5vw;
    height: 6vh;
    margin-left: 7vw;
    margin-top: 3vh;
  }

  .homepage .home__brain {
    display: none;
  }

  .homepage .scroll__incitement {
    display: none;
  }

  .homepage .header__content {
    /* position: absolute;
    width: 100vw;
    left: 0px; */
    top: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: white;
    text-align: center;
  }

  .homepage .content__title {
    font-size: 33px;
    margin-top: 30vh;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
  }

  .homepage .content__text {
    text-align: center;
    font-size: 18px;
    line-height: 2rem;
    font-weight: lighter;
    margin-top: 5vh;
    width: 85vw;
    margin-left: 6.5vw;
  }

  .homepage .hook {
    height: 60vh;
    width: 100vw;
  }

  .homepage .hook__title {
    font-size: 24px;
    font-weight: bolder;
    margin-top: 7vh;
    text-align: center;
    color: #142946;
    width: 90vw;
    margin-left: 5vw;
    line-height: 1.7em;
  }

  .homepage .title__important {
    color: #5ba1e9;
  }

  .homepage .hook__content {
    margin-top: 7vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .homepage .hook__exp--none {
    display: none;
  }

  .homepage .hook__exp {
    text-align: center;
    width: 90vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .homepage .exp__title {
    font-size: 18px;
    font-weight: bold;
  }

  .homepage .exp__text {
    font-size: 14px;
    font-weight: lighter;
    line-height: 2rem;
    color: #8e8b8b;
    margin-top: 5vh;
  }

  .homepage .exp__button {
    font-size: 12px;
    font-weight: bolder;
    height: 5vh;
    width: 80vw;
    border: solid 2px;
    border-image-source: linear-gradient(
      320deg,
      rgb(251, 18, 190),
      rgb(251, 18, 190),
      rgb(96, 157, 229),
      rgb(51, 249, 240),
      rgb(176, 220, 122),
      rgb(253, 216, 106),
      rgb(253, 216, 106)
    );
    border-image-slice: 1;
    margin-top: 4vh;
    background-color: white;
  }

  .homepage .hook__logo {
    display: none;
  }

  .homepage .flexibility {
    width: 100vw;
    height: 38vh;
    background-image: url('https://video.thelab.risinguparis.com/img/background_flexibility.png');
    background-repeat: no-repeat;
    background-size: 1440px 38vh;
    background-position: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .homepage .flexibility__title {
    font-size: 33px;
    font-weight: bolder;
    line-height: 4rem;
    width: 90vw;
    text-align: center;
    margin: auto;
    color: #152a45;
  }

  .homepage .presentiel {
    width: 100vw;
    height: 80vh;
    margin-top: 4vh;
  }

  .homepage .presentiel__background {
    background-image: url('https://video.thelab.risinguparis.com/img/background_presentiel_mobile.png');
    background-repeat: no-repeat;
    background-size: 100vw 20vh;
    height: 20vh;
    text-align: center;
  }

  .homepage .presentiel__hook {
    font-size: 12px;
    font-weight: lighter;
    letter-spacing: 2px;
    color: #ffffff;
    margin-left: 7vw;
    padding-top: 7vh;
  }

  .homepage .presentiel__title {
    font-size: 24px;
    font-weight: bolder;
    margin-left: 7vw;
    margin-top: 2vh;
    color: #ffffff;
  }

  .homepage .presentiel__content {
    height: auto;
    width: 100vw;
    margin-top: 0;
    margin-left: 0;
    border-radius: 6px;
    background-color: white;
    -webkit-box-shadow: 0px 1px 20px #eaeaea;
    box-shadow: 0px 1px 20px #eaeaea;
    overflow: hidden;
  }

  .homepage .presentiel__text {
    font-size: 12px;
    font-weight: bold;
    line-height: 2rem;
    word-spacing: 3px;
    width: 90vw;
    margin-left: 5vw;
    padding-top: 2vh;
    color: #26385b;
    text-align: center;
  }

  .homepage .presentiel__keywords {
    width: 60vw;
    margin-left: 20vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 3vh;
  }

  .homepage .presentiel__keyword {
    color: #787878;
    font-size: 12px;
    font-weight: lighter;
    margin-left: 3vw;
    margin-top: 2vh;
    letter-spacing: 1px;
  }

  .homepage .presentiel__button {
    margin-left: 25vw;
    margin-top: 6vh;
    width: 50vw;
    height: 4vh;
    background-color: #26385b;
    font-size: 12px;
    font-weight: bolder;
    color: white;
    border: none;
    border-radius: 2px;
  }

  .homepage .presentiel__picture {
    display: none;
  }

  .homepage .digital {
    width: 100vw;
    height: 80vh;
  }

  .homepage .digital__background {
    background-image: url('https://video.thelab.risinguparis.com/img/background_digital_mobile.png');
    background-repeat: no-repeat;
    background-size: 100vw 20vh;
    height: 20vh;
    text-align: center;
  }

  .homepage .digital__hook {
    font-size: 12px;
    font-weight: lighter;
    letter-spacing: 2px;
    color: #ffffff;
    margin-left: 8vw;
    padding-top: 7vh;
    text-align: center;
  }

  .homepage .digital__title {
    font-size: 24px;
    font-weight: bolder;
    margin-left: 8vw;
    margin-top: 2vh;
    color: #ffffff;
    text-align: center;
  }

  .homepage .digital__content {
    width: 100vw;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    border-radius: 6px;
    background-color: white;
    -webkit-box-shadow: 0px 1px 20px #eaeaea;
    box-shadow: 0px 1px 20px #eaeaea;
    overflow: hidden;
  }

  .homepage .digital__text {
    font-size: 12px;
    font-weight: bold;
    line-height: 2rem;
    word-spacing: 3px;
    width: 90vw;
    margin-left: 5vw;
    padding-top: 2vh;
    color: #26385b;
    text-align: center;
  }

  .homepage .digital__keywords {
    width: 60vw;
    margin-left: 20vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 3vh;
  }

  .homepage .digital__keyword {
    color: #787878;
    font-size: 12px;
    font-weight: lighter;
    margin-left: 3vw;
    margin-top: 2vh;
    letter-spacing: 1px;
  }

  .homepage .digital__button {
    margin-left: 25vw;
    margin-top: 6vh;
    width: 50vw;
    height: 4vh;
    background-color: #26385b;
    font-size: 12px;
    font-weight: bolder;
    color: white;
    border: none;
    border-radius: 2px;
    float: none;
  }

  .homepage .digital__picture {
    display: none;
  }

  .homepage .slider__puces--home {
    display: none;
  }

  .homepage .home__start {
    width: 100vw;
    height: 20vh;
    margin-top: 0;
    background-color: #152a45;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0px 1px 10px #eaeaea;
    box-shadow: 0px 1px 10px #eaeaea;
  }

  .homepage .start__button {
    margin: auto;
    width: 60vw;
    height: 5vh;
    font-size: 14px;
    font-weight: bolder;
    background-color: white;
    border: solid 2px;
    border-image-source: linear-gradient(
      160deg,
      rgb(253, 216, 106),
      rgb(253, 216, 106),
      rgb(176, 220, 122),
      rgb(51, 249, 240),
      rgb(96, 157, 229),
      rgb(251, 18, 190),
      rgb(251, 18, 190)
    );
    border-image-slice: 1;
    color: #575757;
  }

  .homepage .home__customer {
    width: 100vw;
    height: 50vh;
  }

  .homepage .home__customer--digital {
    width: 100vw;
    height: 50vh;
    margin-top: 23vh;
  }

  .homepage .home__customer--nsh {
    width: 100vw;
    height: 90vh;
  }

  .homepage .customer__title {
    font-size: 24px;
    font-weight: bolder;
    width: 90vw;
    text-align: center;
    padding-top: 0;
    margin-left: 5vw;
    margin-bottom: 7vh;
    word-spacing: 0.2em;
    line-height: 2em;
    color: #101d35;
  }

  .homepage .tech-slideshow {
    will-change: transform;
    height: 200px;
    max-width: 100vw;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  .homepage .tech-slideshow--bw {
    will-change: transform;
    height: 120px;
    max-width: 100vw;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  .homepage .tech-slideshow > div {
    height: 200px;
    width: 3920px;
    background: url('https://video.thelab.risinguparis.com/img/logo_partener.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-repeat: no-repeat;
  }

  .homepage .tech-slideshow--bw > div {
    height: 200px;
    width: 3920px;
    background: url('https://video.thelab.risinguparis.com/img/logo_partener-b&w.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-repeat: no-repeat;
  }

  .homepage .tech-slideshow .mover-1 {
    -webkit-animation: moveSlideshow 13s linear infinite;
    animation: moveSlideshow 13s linear infinite;
  }

  .homepage .tech-slideshow--bw .mover-1 {
    -webkit-animation: moveSlideshow 13s linear infinite;
    animation: moveSlideshow 13s linear infinite;
  }

  @-webkit-keyframes moveSlideshow {
    100% {
      -webkit-transform: translateX(-66.6666%);
      transform: translateX(-66.6666%);
    }
  }

  @keyframes moveSlideshow {
    100% {
      -webkit-transform: translateX(-66.6666%);
      transform: translateX(-66.6666%);
    }
  }

  .homepage .home__contact {
    width: 100vw;
    height: 37vh;
    background-image: url('https://video.thelab.risinguparis.com/img/background_contact.png');
    background-size: 100vw 37vh;
    margin-top: 5vh;
  }

  .homepage .contact__title {
    font-size: 34px;
    font-weight: bolder;
    color: white;
    text-align: center;
    padding-top: 13vh;
    word-spacing: 0.1em;
  }

  .homepage .contact__boutton {
    margin-top: 6vh;
    font-size: 18px;
    font-weight: bold;
    color: #575757;
    width: 17vw;
    height: 5vh;
    border: solid 2px;
    border-image-source: linear-gradient(
      160deg,
      rgb(253, 216, 106),
      rgb(253, 216, 106),
      rgb(176, 220, 122),
      rgb(51, 249, 240),
      rgb(96, 157, 229),
      rgb(251, 18, 190),
      rgb(251, 18, 190)
    );
    border-image-slice: 1;
    margin-left: 41.5vw;
  }
  .homepage .footer__ask {
    background-image: url('https://video.thelab.risinguparis.com/img/image-footer.png');
    background-size: cover;
    overflow: hidden;
    width: 100vw;
    background-position-x: center;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .homepage .ask__text {
    font-family: 'Circular', sans-serif;
    font-weight: bolder;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    width: 80vw;
  }
  .homepage .footer__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .homepage .footer__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .homepage .footer__button {
    background-color: rgb(255, 255, 255, 40%);
    height: 33.7px;
    width: 40vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.2vh 2vw;
  }
  .homepage .button__text {
    font-family: 'Circular', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0.44px;
    color: #ffffff;
  }
  .homepage .articles__footer {
    width: 100vw;
    height: 586px;
    height: 72vh;
    background-image: url(https://video.thelab.risinguparis.com/img/background-bleu.png);
    background-size: 100vw 72vh;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: white;
    position: relative;
  }
  .homepage .articles__footer {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-image: url(https://video.thelab.risinguparis.com/img/background-bleu.png);
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: white;
  }
  .homepage .newsletter__trait {
    margin-left: 5vw;
    width: 90vw !important;
    height: 1px;
    background-color: #ffffff;
    margin-top: 10vh;
    display: block !important;
  }
  .homepage .footer__risingUp {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .homepage .footer__logo {
    width: 16vw;
    margin-top: 2vh;
    margin-left: 0px;
  }
  .homepage .footer__risingUp__text {
    font-size: 10px;
    font-weight: lighter;
    color: white;
    width: 80vw;
    line-height: 2em;
    margin-left: 0px;
    margin-top: 2.5vh;
    text-align: center;
  }
  .homepage .footer__solutions {
    margin-top: 4vh;
    margin-left: 3vw;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 50vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .homepage .footer__ressources {
    margin-top: 4vh;
    margin-left: 0px;
    margin-top: 0px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 50vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 5%;
    top: calc(20vh + 0px);
  }
  .homepage .footer__newsletter {
    margin-top: 4vh;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 0px;
    width: 100%;
  }
  .homepage .footer__solution--bold {
    font-size: 12px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .homepage .newsletter__trait--two {
    margin-left: 5vw;
    width: 90vw !important;
    height: 1px;
    background-color: #ffffff;
    margin-top: 4vh;
    display: block !important;
    margin-left: 45vw;
  }
  .homepage .footer__input {
    width: 243px;
    width: calc(80% - 4vh);
    height: 33px;
    height: 4vh;
    margin-top: 00;
    border: none;
    border-radius: 1px;
    text-indent: 0.2vw;
    float: left;
    margin-left: 4vh;
  }
  .homepage .footer__input__button {
    font-size: 12px;
    font-weight: lighter;
    background-color: #8e8b8b;
    width: calc(20% - 4vh);
    height: 4.4vh;
    border: none;
    border-radius: 1px;
    margin-top: 0;
    /* padding: 15px; */
  }
  .homepage .footer__socials {
    width: 40vw !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    margin-top: 30px;
    position: relative;
  }
  .homepage .footer__social {
    width: 7.5vw;
  }
  .homepage .footer__social--facebook {
    width: 7.5vw;
  }
  .homepage .footer__datas {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 20px;
  }
  .homepage .footer__data__picture {
    width: 10vw;
    display: none;
  }
  .homepage .footer__follow {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 3vh;
    font-family: 'Circular', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 0.14px;
    color: #ffffff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .homepage .newsletter__trait--three {
    margin-left: 1vw;
    width: 90vw !important;
    height: 1px;
    background-color: #ffffff;
    margin-top: 30px;
    display: block !important;
  }
  .homepage .footer__socials.desktop {
    display: none !important;
  }
  .homepage p.footer__solution--bold--new {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }

  .homepage #burgerMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 111;
  }
  .homepage #burgerNav {
    width: 86vw;
    height: 60vh;
    position: fixed;
    padding: 20vh 7vw;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    z-index: 1099999999999990;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    left: 0;
    -webkit-transform: translateX(100vh);
    -ms-transform: translateX(100vh);
    transform: translateX(100vh);
    opacity: 0;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .homepage #burgerNav.active {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    background: white;
  }
  .homepage span#closeBurger {
    text-align: right;
    margin: 30px;
    position: absolute;
    right: 0;
    font-size: 24px;
    top: 0;
  }

  .homepage #burgerMenu button.nav__connexion {
    margin: 0 10%;
    display: block;
    position: static;
    width: 80%;
  }

  .homepage #burgerMenu a.href {
    margin: 0;
    color: #102a47;
    font-weight: 700;
    font-size: 30px;
  }
  .homepage #burgerMenu button.nav__connexion {
    display: none;
  }

  .homepage div#modal,
  .homepage #prix {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .homepage #modal #formTitle,
  .homepage #modal #form,
  .homepage #prix #formTitle,
  .homepage #prix #form {
    width: 100% !important;
    margin: 40px;
  }
  .homepage .nav__connexion.thelabColor {
    width: 40vw;
  }
  .homepage #formTitle .footer__data__picture {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-filter: brightness(0);
    filter: brightness(0);
  }
  .homepage img.modal__logo {
    width: 50px;
  }
  .homepage .home__nav a:first-child {
    z-index: 111;
  }

  .homepage #burgerMenu img {
    width: 20px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 7vw;
    height: 100%;
  }
  .homepage .menuNav .href {
    margin: 0;
  }
  .homepage .href.menuNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .homepage .slider__puces--home {
    display: none;
  }

  .homepage #burgerNav a {
    color: #102a47;
  }
  .homepage #modal #form {
    margin-top: 0;
  }
  .homepage #burgerNav div#solutionsNav {
    position: relative;
    top: unset;
    opacity: 1;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 20px;
    height: auto;
    font-size: 12px;
  }

  .homepage #burgerNav .href.menuNav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .homepage #burgerNav a.solutionNav {
    border: none !important;
    padding: 0 !important;
  }
  #cookies-eu-banner div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #cookies-eu-banner p {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
