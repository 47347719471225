.format_quizz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.format_quizz .format_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.answer {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}

input[type='radio'],
input[type='checkbox'] {
  display: none;
}
.delete_question {
  display: none;
  position: absolute;
  right: 5px;
}
.answer label:hover .delete_question {
  display: flex;
}
.answer label {
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.maskCover .answer label {
  padding: 10px 45px;
}
button.btn_primary[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.maskCover .btn_wrapper {
  padding: 0 45px;
}
input[type='radio']:checked + label,
input[type='checkbox']:checked + label {
  background-color: rgb(216 216 216 / 16%);
}
.format_wrapper input[type='radio']:checked + label,
.format_wrapper input[type='checkbox']:checked + label {
  background-color: rgba(255, 255, 255, 0.5);
}
input[type='radio'] + label .unchecked,
input[type='checkbox'] + label .unchecked {
  display: inline-block;
  min-width: 16px;
  height: 16px;
  width: 16px;
  min-height: 16px;
  cursor: pointer;
  border: solid 2px white;
  border-radius: 50%;
  margin-right: 25px;
}
input[type='checkbox'] + label .unchecked {
  border-radius: 4px;
}
input[type='radio'] + label .checked,
input[type='checkbox'] + label .checked {
  margin: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  display: flex;
}
input[type='checkbox'] + label .checked {
  border-radius: 2px;
}
.checked img {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
}

/* .checked.checkAnswered {
  background: none !important ; */
/* } */
input[type='radio'] + label .badCheck,
input[type='checkbox'] + label .badCheck {
  display: none;
}
input[type='radio']:checked + label span[isvalid='false'] .badCheck,
input[type='checkbox']:checked + label span[isvalid='false'] .badCheck {
  display: initial;
}
.checked span[isvalid='true'] .badCheck {
  display: none;
}
.checked span[isvalid='false'] .goodCheck {
  display: none;
}

span.checked.checkAnswered > span {
  height: 100%;
  position: relative;
  width: 100%;
}
img.badCheck {
  padding: 1px;
  width: 8px;
  height: 8px;
}

.format_mask_content h4 {
  padding: 20px 45px 5px;
}

.format_wrapper .answerWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.format_wrapper .answerWrapper p[contenteditable] {
  min-width: 20px;
}
.format_wrapper .answer {
  margin-right: 20px;
}
.format_wrapper .answer label {
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.14);
  padding-right: 20px;
}
.format_wrapper input[type='radio'] + label .unchecked,
.format_wrapper input[type='checkbox'] + label .unchecked {
  margin-right: 10px;
}
span.checked.checkAnswered {
  background: none !important;
}
.question_points {
  position: absolute;
  width: 27px;
  background: none;
  border: none;
  color: white;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.format_wrapper .question_points {
  left: -10px;
}
@media (max-width: 600px) {
  .format_quizz.formatBox {
    flex-direction: column;
  }

  .format_quizz.formatBox > .format_mask {
    height: auto;
    width: 100%;
    order: 2;
  }

  .format_quizz.formatBox .format_mask.maskCover {
    /*min-width: unset;
    width: auto;*/
    width:90%;
    max-width: 90%;
  }

  .format_diag.formatBox .format_mask.maskCover {
    /*min-width: unset;
    width: auto;*/
    width:100%;
    max-width: 100%;
  }
  .format_diag.formatBox .format_mask.maskCover .feedback_donut {
    padding: 20px;
  }

  .format_quizz.formatBox > .format_mask > img,
  .format_quizz.formatBox .format_mask.maskCover > img {
    display: none;
  }

  .format_quizz.formatBox > .format_wrapper {
    order: 1;
    width: calc(100% - 50px);
    height:auto;
    /*flex-shrink: 0;*/
  }

  .format_quizz.formatBox > .format_wrapper > div:nth-child(2) {
    flex-grow: 1;
  }

  .format_quizz.formatBox > .format_mask > .format_mask_content,
  .format_quizz.formatBox .format_mask.maskCover > .format_mask_content {
    position: static;
    top: 0;
    transform: none;
    overflow-y: auto;
  }

  .format_quizz.formatBox > .format_wrapper > div {
    display: flex;
    flex-direction: column;
  }

  .format_quizz.formatBox > .format_wrapper div.answerWrapper {
    flex-direction: column;
    flex-grow: 1;
  }

  .format_quizz.formatBox > .format_wrapper div.answerWrapper > .answer {
    margin-right: 0;
  }

  .format_quizz.formatBox > .format_wrapper .main_wrapper > .format_mask.maskCover {
    height: auto;
  }

  .format_quizz div.slider {
    padding: 0 10px;
  }
}
