.homepage html,
.homepage body,
.homepage div,
.homepage span,
.homepage applet,
.homepage object,
.homepage iframe,
.homepage h1,
.homepage h2,
.homepage h3,
.homepage h4,
.homepage h5,
.homepage h6,
.homepage p,
.homepage blockquote,
.homepage pre,
.homepage a,
.homepage abbr,
.homepage acronym,
.homepage address,
.homepage big,
.homepage cite,
.homepage code,
.homepage del,
.homepage dfn,
.homepage em,
.homepage img,
.homepage ins,
.homepage kbd,
.homepage q,
.homepage s,
.homepage samp,
.homepage small,
.homepage strike,
.homepage strong,
.homepage sub,
.homepage sup,
.homepage tt,
.homepage var,
.homepage b,
.homepage u,
.homepage i,
.homepage center,
.homepage dl,
.homepage dt,
.homepage dd,
.homepage ol,
.homepage ul,
.homepage li,
.homepage fieldset,
.homepage form,
.homepage label,
.homepage legend,
.homepage table,
.homepage caption,
.homepage tbody,
.homepage tfoot,
.homepage thead,
.homepage tr,
.homepage th,
.homepage td,
.homepage article,
.homepage aside,
.homepage canvas,
.homepage details,
.homepage embed,
.homepage figure,
.homepage figcaption,
.homepage footer,
.homepage header,
.homepage hgroup,
.homepage menu,
.homepage nav,
.homepage output,
.homepage ruby,
.homepage section,
.homepage summary,
.homepage time,
.homepage mark,
.homepage audio,
.homepage video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
.homepage article,
.homepage aside,
.homepage details,
.homepage figcaption,
.homepage figure,
.homepage footer,
.homepage header,
.homepage hgroup,
.homepage menu,
.homepage nav,
.homepage section {
  display: block;
}

.homepage ol,
.homepage ul {
  list-style: none;
}
.homepage blockquote,
.homepage q {
  quotes: none;
}
.homepage blockquote:before,
.homepage blockquote:after,
.homepage q:before,
.homepage q:after {
  content: '';
  content: none;
}
.homepage table {
  border-collapse: collapse;
  border-spacing: 0;
}
