/* Format Video */
.video_overlay {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.video_overlay.isHidden {
  display: none;
}

.format_video.formatBox {
  position: relative;
}

div#videoBox.isHidden > div {
  display: none;
}

div#videoBox {
  opacity: 0.35;
  width: 100%;
  height: 100%;
}
div#videoBox > img {
  width: 100%;
  height: 100%;
}
div#videoBox.isPlaying > img {
  display: none;
}
div#videoBox.isPlaying {
  opacity: 1;
}
div#videoBox video {
  object-fit: cover;
}
div#videoBox.isFullscreen video {
  object-fit: contain;
}
.videoControl {
  position: absolute;
  top: 0;
  width: 370px;
  top: 50%;
  left: -155px;
  display: flex;
  opacity: 0;
  transition: 300ms;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%) rotate(-90deg);
}
@media (max-width: 1060px) {
  .videoControl {
    width: 300px;
    left: -120px;
  }
}
.videoControl button {
  transform: rotate(90deg);
  background: none;
  border: none;
  padding: 0;
}
.videoControl span {
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}
progress {
  width: 100%;
  height: 20px;
}
input[type='range'] {
  position: absolute;
  height: 17px;
  opacity: 0;
  width: 100%;
  margin: 0;
}
.progressInput {
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressInput.sound input,
.progressInput.sound progress {
  transition: 500ms;
  width: 0;
}
.progressInput.sound:hover input,
.progressInput.sound:hover progress {
  width: 60px;
  margin-right: 10px;
}
.progressInput.sound input {
  right: 16px;
  left: unset;
}
.progressInput.sound {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#videoBox:hover .videoControl {
  opacity: 1;
}

div.videoBox > img.mobile-play-button {
  display: none;
}

div.mobile-video-wrapper {
  display: none;
}

@media (max-width: 600px) {
  div.videoBox {
    display: none;
  }

  div.mobile-video-wrapper > div.videoBox {
    display: block;
    margin-top: 16px;
    position: relative;
  }

  div.mobile-video-wrapper {
    position: relative;
  }

  div.videoBox > img {
    border-radius: 5px;
  }

  .format_video.formatBox {
    flex-grow: 1;
    display: flex;
  }

  .format_video.formatBox > .video_overlay {
    position: static;
    height: auto;
    flex-grow: 1;
  }

  .format_video.formatBox > .video_overlay > .format_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: calc(100% - 30px);
  }

  .format_video.formatBox > .video_overlay > .format_wrapper > .main_wrapper > div.videoBox {
    display: block;
    margin-top: 16px;
  }

  div.mobile-video-wrapper > img.mobile-play-button {
    left: 40%;
    position: absolute;
    top: 40%;
    max-width: 20%;
    display: inline;
  }

  div.mobile-video-wrapper {
    display: block;
  }

  .videoControl {
    flex-direction: row;
    transform: unset;
    left: 0;
    align-items: center;
    width: auto;
    position: relative;
    z-index: 100;
    opacity: 1;
    margin-top: 8px;
  }

  .videoControl button {
    transform: unset;
  }

  .videoControl .progressInput.sound > img {
    transform: unset !important;
  }
}
