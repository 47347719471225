.formatBox h1,
.formatBox h4 {
  font-family: 'Aileron', sans-serif;
  text-transform: uppercase;
}

.formatBox h1 {
  font-weight: 200;
  font-size: 50px;
}
.formatBox h1.xs {
  font-size: 20px;
  margin-bottom: 3px;
}

.formatBox h2 {
  font-weight: 400;
  font-size: 39px;
  margin-bottom: 20px;
}

.formatBox h3 {
  font-weight: 400;
  font-size: 27px;
  margin-bottom: 10px;
}

.formatBox h4,
.formatBox h5 {
  font-weight: 600;
  font-size: 20px;
}

.formatBox p {
  font-family: 'Aileron', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1060px) {
  .formatBox h1 {
    font-size: 40px;
  }
  .formatBox h1.xs {
    font-size: 16px;
  }
  .formatBox h2 {
    font-size: 32px;
  }
  .formatBox h3 {
    font-size: 22px;
  }
  .formatBox h4,
  .formatBox h5 {
    font-size: 16px;
  }
  .formatBox p {
    font-size: 14px;
  }
}
