span.MuiSlider-root,
span.MuiSlider-rail,
span.MuiSlider-track {
  height: 12px;
}
span.MuiSlider-rail,
span.MuiSlider-track {
  border-radius: 50px;
}
span.MuiSlider-thumb {
  background: white;
}
span.MuiSlider-markLabel {
  color: rgb(200, 200, 200);
}
span.MuiSlider-markLabel.MuiSlider-markLabelActive {
  color: white;
}
span.MuiSlider-track {
  background: #ffffff91;
}
span.MuiSlider-rail {
  background-color: rgba(255, 255, 255, 0.315);
}
span.MuiSlider-mark {
  display: none;
}
.slider {
  margin-top: 30px;
}

span.MuiSlider-thumb.MuiSlider-thumbColorPrimary {
  height: 30px;
  width: 30px;
  top: 50%;
  transform: translateY(-50%);
  border: solid 8px white;
  margin: 0;
  margin-left: -15px;
}
span.MuiSlider-markLabel {
  margin-top: 10px;
}


span.MuiSlider-vertical .MuiSlider-root,
span.MuiSlider-vertical .MuiSlider-rail,
span.MuiSlider-vertical .MuiSlider-track,
.verticalSliderCustom {
  width: 12px;
  height: 100% !important;
}

span.MuiSlider-vertical .MuiSlider-rail,
span.MuiSlider-vertical .MuiSlider-track,
.verticalSliderCustom {
  border-radius: 50px;
}
/*.verticalSliderCustom .MuiSlider-thumb {
  background: white;
}
.verticalSliderCustoml .MuiSlider-markLabel {
  color: rgb(200, 200, 200);
  margin-left: 15px;
}
.verticalSliderCustom .MuiSlider-track {
  background: #ffffff91;
}
.verticalSliderCustom .MuiSlider-rail {
  background-color: rgba(255, 255, 255, 0.315);
}
.verticalSliderCustom .MuiSlider-mark {
  display: none;
}*/
.verticalSliderCustom .MuiSlider-thumb.MuiSlider-thumbColorPrimary {
  height: 30px;
  width: 30px;
  left:50%;
  top : unset;
  transform: translateX(-50%);
  border: solid 8px white;
  margin: 0;
  margin-bottom: -15px;
}


.format_questionnaire .format_mask_content {
  overflow: hidden;
}
p.questionnaire_icon {
  text-align: center;
  font-size: 234px;
  font-family: 'Circular', sans-serif;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.47) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

@media (max-width: 1060px) {
  p.questionnaire_icon {
    font-size: 180px;
  }
}

@media (max-width: 600px) {

  .format_questionnaire.formatBox .format_mask.maskCover {
    display:none;
  }

  .format_quizz.format_questionnaire.formatBox {
    align-items: stretch;
  }

  .format_quizz.format_questionnaire.formatBox .number-background {
    position: absolute;
    top: 10%;
    z-index: -1;
    width: 50%;
    left: 25%;
  }

  .format_quizz.format_questionnaire.formatBox .format_mask.maskCover > .format_mask_content {
    position: relative;
  }

  .format_quizz.format_questionnaire.formatBox > .format_wrapper {
    flex-grow: 1;
  }

  .format_quizz.format_questionnaire.formatBox > .format_wrapper > div:nth-child(2) {
    flex-grow: 1;
  }
}

@media (max-width: 400px) {
  p.questionnaire_icon {
    font-size: 140px;
  }
}

@media (max-width: 300px) {
  p.questionnaire_icon {
    font-size: 80px;
  }
}
