.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.7);
    z-index:99999;
    display:none;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FF0000;
    font-weight:bold;
    font-size:25px;
    display:flex;
    flex-direction:column;
    align-items: center;
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #FF0000;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    margin-bottom: 20px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/** PLAYER WRAPPER **/

.formation_wrapper {
  display: flex;
}
.player_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.player {
  max-width: 949px;
  width: 100%;
  height: 558px;
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.formatBox {
  height: 499px;
  position: relative;
}
.format_wrapper {
  max-height: calc(100% - 40px);
  overflow-y: scroll;
  padding: 20px 45px;
  width: 100%;
}
.player .account label {
  color: white !important;
  margin-top: 20px;
}
.player .account input {
  width: 205px;
  height: 35px;
  margin-top: 5px;
  text-indent: 20px;
}
@media (max-width: 1060px) {
  .player {
    max-width: 749px;
    height: 440px;
  }
  .formatBox {
    height: 391px;
  }
  .format_wrapper {
    padding: 15px 25px;
    max-height: calc(100% - 30px);
  }
}
@media (max-width: 840px) {
}
@media (max-width: 600px) {
  .noDisplayForMobile {
    display : none !important;
  }

  .formatBox,
  .player,
  .formation_wrapper,
  .player_wrapper {
    flex-grow: 1;
    height: auto;
    min-height: 100%;
  }

  .player_wrapper {
    width:calc(100% - 50px);
  },


  .formatBox .format_wrapper {
    overflow-y: auto;
  }

  .format_mask > .title_icon_wrapper {
    position: initial;
  }

  .format_mask > .title_bkgr {
    display: none;
  }

  .main_wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn_primary,
  .btn_secondary {
    display: block;
    width: 100%;
    margin-top: 8px;
    outline: none;
  }

  .format_titre.formatBox {
    flex-direction: column;
  }

  .format_titre > .format_mask {
    flex-shrink: 1;
    height: auto !important;
    min-width: auto !important;
    width: auto !important;
    position: static !important;
    padding : 40px 0px;
  }

  .format_titre > .format_mask > .title_icon_wrapper > img.title_icon {
    width: auto;
  }

  .player > .format_titre > .format_wrapper {
    width: auto;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .format_text.formatBox {
    flex-direction: column;
    align-items: stretch;
  }

  .format_text.formatBox .format_mask {
    width: 100%;
    flex : 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position : center;
    margin: auto;
  }

  .format_text.formatBox .format_mask.maskCover {
    mask-image : unset;
  }

  .format_text.formatBox > .format_wrapper {
    flex : 1 1 100%;
    order: 1;
    width: auto;
    height: auto;
  }

  .format_text.formatBox > .format_mask > img {
    display: none;
  }

  .format_text.formatBox > .format_mask > .format_mask_content {
    position: static;
    top: 0;
    transform: none;
    overflow-y: hidden;
  }

  .format_text.formatBox > .format_wrapper > form > .form-fields > .account {
    align-items: stretch;
    max-width: unset;
  }

  .format_text.formatBox > .format_wrapper > form > .form-fields > .account > input {
    width: auto;
    outline: none;
  }

  .format_text.formatBox > .format_wrapper {
    display: flex;
    flex-direction: column;
  }

  .format_text.formatBox > .format_wrapper > form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .format_text.formatBox > .format_wrapper > form > .form-fields {
    flex-grow: 1;
  }

  .formation.landing,
  .formation.landing .quote {
    padding: 0;
  }

  .format_quizz .main_wrapper {
    justify-content: space-evenly;
  }
}

/** PLAYER TOPBAR **/
.playerTitle {
  height: 37px;
  display: flex;
  align-items: center;
}
.playerTitle h2 {
  font-family: 'Aileron', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}

/** PLAYER NAVBAR **/
.player_nav {
  display: flex;
  margin-top: 12px;
}

:root {
  --vh: 1vh;
}

@media (max-width: 600px) {
  .player_nav {
    display: none;
  }
  .player .formatBox,
  .player .player_nav {
    margin-bottom: 0px !important;
    overflow:auto;
    height : calc(calc(var(--vh, 1vh) * 100) - 37px) !important;
    min-height : calc(calc(var(--vh, 1vh) * 100) - 37px) !important;
    max-height : calc(calc(var(--vh, 1vh) * 100) - 37px) !important;
  }

  .landingPage .player .formatBox,
  .player .player_nav {
    margin-bottom: 0px !important;
    overflow:auto;
    height : calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
    min-height : calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
    max-height : calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
  }
}
.format_dot {
  background: rgba(248, 248, 248, 0.4);
  height: 12px;
  width: 12px;
  margin-right: 10px;
  border-radius: 100%;
}
.format_dot_isdone {
  cursor: pointer;
}
.format_dot.format_dot_doing {
  height: 8px;
  width: 8px;
  border: 2px solid white;
}

/* TRANSITION */
.player .formatBox > * {
  opacity: 1;
  transition: 500ms ease-in-out;
}
.player.format_transition .formatBox > * {
  opacity: 0;
  transition: 0ms;
}

/* OPENING ANIMATION */
.player_opening.player .formatBox,
.player_opening.player .player_nav {
  opacity: 0;
  height: 0;
}
.player .formatBox,
.player .player_nav {
  opacity: 1;
  transition: 500ms ease-in-out 2s;
  margin-bottom: 23px;
}

.player_opening.player .playerTitle {
  opacity: 0;
  width: 0;
}
.playerTitle {
  width: 100%;
  transform-origin: center left;
  transition: 500ms ease-in-out;
  animation: playerTitle 2s ease-in-out both;
  opacity: 1;
}
@keyframes playerTitle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

/* OTHER */

.format_mask_content.mask_inline_image {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* DIAGNOSTIC ANIMATION */
.loader-ring {
  width: 200px;
  height: 200px;
  position: relative;
  margin-top: 30px;
}

.loader-ring-light {
  width: 200px;
  height: 200px;
  border-radius : 200px;
  animation: rotate-360 2s linear infinite;
}

.loader-ring-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius : 200px;
  box-shadow : 0 0 10px 4px rgba(0, 0, 0, .3) inset;
}

@keyframes rotate-360 {
  from {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.quizzLeftTransition {
  animation: fadeIn 2s linear;
}

.animOpacity0 {
  animation-fill-mode: both !important;
  opacity : 0;
}
