@import './reset.css';
@import './home.css';
@import './homeMobile.css';


.homepage header.header__home {
  background: none;
  height: 9vh;
}
.homepage .home__customer--digital .mover-1 {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.homepage a.href {
  color: #152a45;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.homepage .apprenticeship {
  width: 86vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7vw;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.homepage .apprenticeship__wallets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.homepage .apprenticeship__wallets--right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.homepage .wallet {
  width: 15vw;
  height: 200px;
  border-radius: 5px;
  margin-left: 2vw;
  -webkit-box-shadow: 0px 1px 20px #eaeaea;
  box-shadow: 0px 1px 20px #eaeaea;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #152a45;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.homepage .wallet--first {
  width: 15vw;
  height: 200px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 20px #eaeaea;
  box-shadow: 0px 1px 20px #eaeaea;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #152a45;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.homepage .wallet--active {
  width: 15vw;
  height: 200px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.homepage .wallet--active#wallet--one,
.homepage .wallet--active--other#wallet--four {
  background: -o-radial-gradient(#9c5cda, #f49fe5);
  background: radial-gradient(#9c5cda, #f49fe5);
}
.homepage .wallet--active--other#wallet--two,
.homepage .wallet--active--other#wallet--five {
  background: -o-radial-gradient(#a1e6a3, #69efc5);
  background: radial-gradient(#a1e6a3, #69efc5);
}
.homepage .wallet--active--other#wallet--three,
.homepage .wallet--active--other#wallet--six {
  background: -o-radial-gradient(#fcd86b, #d6de82);
  background: radial-gradient(#fcd86b, #d6de82);
}

.homepage .wallet--active--other {
  width: 15vw;
  height: 200px;
  background-color: #152a45;
  color: white;
  margin-left: 2vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.homepage .wallet__text {
  margin: auto;
  text-align: center;
  width: 8vw;
  font-size: 12px;
  font-weight: lighter;
  line-height: 1.5rem;
}

.homepage .text--litle {
  width: 5vw;
}

.homepage .apprenticeship__text {
  width: 30vw;
  height: 16vh;
  line-height: 2rem;
  font-size: 18px;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_one.png');
  background-size: 5vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 5vh;
  background-size: contain;
  margin-left: 4vw;
}

.homepage .apprenticeship__text--two {
  width: 30vw;
  height: 16vh;
  line-height: 2rem;
  background-size: contain;
  font-size: 18px;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_two.png');
  background-size: 6vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 5vh;
  margin-left: 4vw;
}

.homepage .apprenticeship__text--three {
  width: 33vw;
  height: 16vh;
  background-size: contain;
  line-height: 2rem;
  font-size: 18px;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_three.png');
  background-size: 6vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 5vh;
  margin-left: 4vw;
}

.homepage .apprenticeship__text--left--four {
  width: 34vw;
  height: 16vh;
  line-height: 2rem;
  background-size: contain;
  font-size: 18px;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_one.png');
  background-size: 5vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 4vh;
}

.homepage .apprenticeship__text--none {
  display: none;
}

.homepage .apprenticeship__text--left--five {
  width: 34vw;
  height: 16vh;
  line-height: 2rem;
  font-size: 18px;
  background-size: contain;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_two.png');
  background-size: 6vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 5vh;
}

.homepage .apprenticeship__text--left--six {
  width: 34vw;
  height: 16vh;
  line-height: 2rem;
  background-size: contain;
  font-size: 18px;
  font-weight: bolder;
  background-image: url('https://video.thelab.risinguparis.com/img/number_three.png');
  background-size: 6vw 16vh;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #152a45;
  padding-top: 4vh;
}

.homepage .titleLab h1 {
  font-size: 123px;
  font-weight: 400;
}
.thelabColor {
  background: -o-linear-gradient(
    130deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  background: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 600% 600%;
  -webkit-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 30% 0%;
  }
  50% {
    background-position: 71% 100%;
  }
  100% {
    background-position: 30% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 30% 0%;
  }
  50% {
    background-position: 71% 100%;
  }
  100% {
    background-position: 30% 0%;
  }
}

.homepage .titleLab {
  width: 45%;
}

.homepage section#titleLab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 7vw 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.homepage .titleLab h2 {
  font-size: 18px;
  line-height: 44px;
  font-weight: 900;
  margin-top: 20px;
}

.homepage button.nav__connexion.labButton {
  margin: 20px 00;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
}

.homepage .imageHeaderLab {
  width: 45%;
  border-radius: 3px;
  height: 50vh;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.homepage section.hook {
  margin: 100px 0 120px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20vh;
  overflow-x: hidden;
}

.homepage h4.hook__title {
  margin: 0;
  position: absolute;
  -webkit-transform: translateY(60px);
  -ms-transform: translateY(60px);
  transform: translateY(60px);
}

.homepage h4.hook__title.background {
  opacity: 0.05;
  font-size: 123px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: static;
  margin-top: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.homepage div#wallet--one {
  margin: 0;
}

.homepage section.pourquoiLab {
  padding: 30px 7vw;
}

.homepage .pourquoiLab h2 {
  font-size: 33px;
  font-weight: 900;
}

.homepage .pourquoiLab h4 {
  font-size: 18px;
  margin-top: 20px;
  color: #8e8b8b;
  font-weight: lighter;
}

.homepage .pourquoiBannieres {
  margin: 30px 0;
  background: -o-radial-gradient(#85a2db, #8f92dc);
  background: radial-gradient(#85a2db, #8f92dc);
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 0px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
}

.homepage .pourquoi_experts h3 {
  font-size: 18px;
  font-weight: 700;
}

.homepage .pourquoi_experts p {
  font-size: 16px;
  line-height: 31px;
  margin-top: 20px;
  font-weight: lighter;
}

.homepage .pourquoi_experts {
  width: 50%;
  padding: 0 5%;
}

.homepage .pourquoi_experts.middle {
  border-left: solid 1px white;
  border-right: solid 1px white;
  text-align: center;
}

.homepage .pourquoi_experts.right {
  text-align: end;
  border-left: 1px white solid;
}

.homepage section.quiLab {
  padding: 10px 7vw 100px 7vw;
}
.homepage .titleLab h1 {
  font-size: 8vw;
}

.homepage .quiLab h2 {
  font-size: 33px;
  font-weight: 900;
  text-align: end;
}

.homepage .quiLab h4 {
  font-size: 18px;
  margin-top: 20px;
  color: #8e8b8b;
  font-weight: 100;
  text-align: end;
}

.homepage section section.apprenticeship {
  padding: 0;
  margin: 40px 0;
}

.homepage #buttonColor button.nav__connexion.thelabColor {
  margin: 0;
}

.homepage section#buttonColor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  margin: 0px 0 100px;
}

.homepage button.nav__connexion {
  margin: 0;
  color: #575757;
  font-weight: 700;
}

.homepage .underline--solutions {
  -webkit-filter: brightness(0.3);
  filter: brightness(0.3);
}
.homepage #modal button.nav__connexion.labButton {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.homepage .homepage div#prix {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: static;
  padding: 9vh 7vw;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  overflow: hidden;
  opacity: 1;
  height: auto;
}
.homepage .tech-slideshow--bw {
  height: 120px;
  margin-top: 40px;
}
.homepage #prix .tech-slideshow--bw {
  margin-top: 10px;
}
.homepage #modal section#titleLab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.homepage #modal h1.thelabColor {
  font-size: 123px;
  font-weight: 400;
}
.homepage #modal .thelabColor {
  background: -o-linear-gradient(
    130deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  background: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 600% 600%;
  -webkit-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}
.homepage #modal button.nav__connexion.labButton,
.landingPage .labButton {
  margin-bottom: 0;
  background: -o-linear-gradient(
    130deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  background: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  color: white;
  border: none;
  border-radius: 50px;
  background-size: 600% 600%;
  -webkit-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
.homepage #modal section#titleLab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 00;
}
.homepage #modal button.nav__connexion.labButton {
  font-size: 14px;
  padding: 10px 60px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  /* margin: auto; */
}

@media (max-width: 860px) {
  .homepage main {
    overflow: hidden;
  }
  .homepage h4.hook__title.background {
    display: none;
  }
  .homepage #titleLab {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .homepage .home__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .homepage img.header__logo {
    width: 60px;
  }
  .homepage nav {
    display: none !important;
  }
  .homepage header .nav__connexion {
    display: none;
    /* align-items: center; */
  }
  .homepage #modal button.nav__connexion.labButton {
    font-size: 14px;
  }

  .homepage h1.thelabColor {
    font-size: 65px;
  }
  .homepage .titleLab h2 {
    font-size: 16px;
    margin-top: 30px;
  }
  .homepage button.nav__connexion.labButton {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 80px;
  }
  .homepage .titleLab {
    width: 100%;
    text-align: center;
  }
  .homepage #modal h1.thelabColor {
    font-size: 59px;
  }
  .homepage .imageHeaderLab {
    width: 100%;
  }
  .homepage section.apprenticeship {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .homepage .wallet {
    width: 30vw;
    height: 30vw;
  }
  .homepage p.wallet__text {
    margin: auto 3px;
    width: 100%;
  }
  .homepage .apprenticeship p {
    width: 100%;
    margin: 100px 0 100px;
    height: auto;
    background-size: contain;
    padding: 0;
  }
  .homepage .wallet p,
  .homepage .wallet--active--other p,
  .homepage .wallet--active p {
    width: 100%;
    margin: auto 0;
  }
  .homepage .wallet--active--other {
    width: 30vw;
    height: 30vw;
  }

  .homepage .pourquoiLab h2,
  .homepage .quiLab h2 {
    text-align: center;
    font-size: 24px;
  }
  .homepage .pourquoiLab h4,
  .homepage .quiLab h4 {
    text-align: center;
    font-size: 16px;
    line-height: 33px;
  }
  .homepage .pourquoiBannieres {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
  }
  .homepage section.pourquoiLab {
    padding-bottom: 0;
  }
  .homepage .pourquoi_experts {
    width: 80%;
    text-align: center;
    padding: 30px 0px;
    margin: 0 10%;
  }
  .homepage .pourquoi_experts.middle {
    border: solid 1px white;
    border-left: none;
    border-right: none;
  }
  .homepage .pourquoi_experts.right {
    text-align: center;
  }
  .homepage .quiLab .apprenticeship {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 30px 0 0 0;
  }
  .homepage .quiLab {
    padding-bottom: 0 !important;
  }
  .homepage button.nav__connexion.thelabColor {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px 20px;
  }

  .homepage .home__customer--digital {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 80px;
    margin-top: 0;
  }

  .homepage #modal section#titleLab {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .homepage #modal button.nav__connexion.labButton {
    margin: 20px auto;
  }
  .homepage #modal div#form {
    text-align: center;
  }
  .homepage input#mauticform_input_thelabcommingsoon_email {
    text-align: center;
  }
  .homepage .mauticform-message {
    top: 10px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    margin: 0px 20px 10px 20px;
    width: calc(100% - 40px);
  }
  .homepage section.hook {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 50px 0 100px;
    overflow: hidden;
  }

  .homepage h4.hook__title {
    font-size: 36px;
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .homepage section.quiLab {
    padding: 100px 7vw;
  }
}

.homepage button.nav__connexion.labButton {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 80px;
}

.homepage .menuNav .href {
  margin: 0;
  cursor: pointer;
}

.homepage .wallet--active--other p,
.homepage .wallet--active p {
  font-weight: bolder;
}

.homepage .nav__connexion.thelabColor {
  margin: 0;
  text-align: center;
  height: auto;
  padding: 10px 0;
  cursor: pointer;
}

@media (max-width: 345px) {
  .homepage .pourquoiLab h2,
  .homepage .quiLab h2 {
    text-align: center;
    font-size: 20px;
  }
}

.homepage #modal #form h2 {
  font-size: 24px;
  margin-bottom: 30px;
}
.homepage .mauticform_wrapper {
  max-width: 600px;
  margin: 10px auto;
}

.homepage .mauticform-name {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 3px;
}
.homepage .mauticform-description {
  margin-top: 2px;
  margin-bottom: 10px;
}
.homepage .mauticform-error {
  margin-bottom: 10px;
  color: red;
}
.homepage .mauticform-message {
  margin: 10px 0 0;
  color: green;
  font-size: 12px;
}
.homepage .mauticform-row {
  display: block;
  margin-bottom: 20px;
}
.homepage .mauticform-label {
  font-size: 1.1em;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.homepage .mauticform-row.mauticform-required .mauticform-label:after {
  color: #e32;
  content: ' *';
  display: inline;
}
.homepage .mauticform-helpmessage {
  display: block;
  font-size: 0.9em;
  margin-bottom: 3px;
}
.homepage .mauticform-errormsg {
  display: block;
  color: red;
  margin-top: 2px;
}
.homepage .mauticform-selectbox,
.homepage .mauticform-input,
.homepage .mauticform-textarea {
  width: 100%;
  padding: 0.5em 0.5em;
  border: 1px solid #ccc;
  background: #fff;
  -webkit-box-shadow: 0px 0px 0px #fff inset;
  box-shadow: 0px 0px 0px #fff inset;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.homepage .mauticform-checkboxgrp-row {
}
.homepage .mauticform-checkboxgrp-label {
  font-weight: normal;
}
.homepage .mauticform-checkboxgrp-checkbox {
}
.homepage .mauticform-radiogrp-row {
}
.homepage .mauticform-radiogrp-label {
  font-weight: normal;
}
.homepage .mauticform-radiogrp-radio {
}
.homepage .mauticform-button-wrapper .mauticform-button.btn-default,
.homepage .mauticform-pagebreak-wrapper .mauticform-pagebreak.btn-default {
  color: #5d6c7c;
  background-color: #ffffff;
  border-color: #dddddd;
}
.homepage .mauticform-button-wrapper .mauticform-button,
.homepage .mauticform-pagebreak-wrapper .mauticform-pagebreak {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.3856;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.homepage .mauticform-button-wrapper .mauticform-button.btn-default[disabled],
.homepage .mauticform-pagebreak-wrapper .mauticform-pagebreak.btn-default[disabled] {
  background-color: #ffffff;
  border-color: #dddddd;
  opacity: 0.75;
  cursor: not-allowed;
}
.homepage .mauticform-pagebreak-wrapper .mauticform-button-wrapper {
  display: inline;
}

.homepage .titleLab span.titleInfo {
  font-size: 18px;
}
.homepage #buttonColor .nav__connexion {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
}

.homepage #buttonColor .nav__connexion span {
  padding-left: 8px;
}
#prix h5.customer__title {
  margin: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding-top: 30px;
  text-align: left;
}

.buttonConnection a p {
  margin-right: 30px;
  color: #575757;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 4vh;
  font-weight: 700;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.buttonConnection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4vh !important;
  margin-right: 7vw !important;
}
.buttonConnection a {
  text-decoration: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.landingPage {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

@media (max-width: 600px) {
  .landingPage {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.landing_content {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0 0;
}

.formation.landing,
.formation.landing .quote {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 600px) {
  .formation.landing,
  .formation.landing .quote {
    height: auto;
  }
}

.landing_content h1.landing_title {
  font-size: 55px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.landing_content h1.landing_title.background {
  opacity: 0.05;
  font-size: 123px;
  position: absolute;
  top: 0;
  left: 50%;
  height: 139px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform: translateX(-50%);
  background: none;
}

.landing_content .landing_title_wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
  transition: 400ms;
  opacity: 1;
}

.landing_content h1.landing_title {
  font-size: 55px;
  color: white;
  font-weight: bold;
  text-align: center;
}
.landing_content .account label {
  color: white;
}
.landing_content button.next.labButton {
  position: inherit;
  border: solid 2px;
}
.landing_content form button.next.labButton {
  width: 100%;
  margin-top: 40px;
}

.landing_intro_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 400ms;
  opacity: 1;
}
.landing_intro_wrapper p {
  font-size: 22px;
  color: white;
  font-weight: 300;
  text-align: center;
  margin: 40px;
}
.landing_intro_wrapper button {
  align-self: center !important;
  min-width: 250px !important;
  margin: 0 !important;
}

.landing_content.isAuthTime .landing_intro_wrapper {
  opacity: 0;
}

.landing_content.animationEnded .landing_title_wrapper {
  margin-top: 9vh;
}

.landing_content.animationEnded .landing_intro_wrapper {
  display: none;
}
.landing_content form.scoringAuth {
  opacity: 0;
  transform: translateY(100%);
  transition: 400ms;
  margin-top: 10px;
}
.landing_content form .account {
  margin-top: 30px;
}
.landing_content form .account label {
  margin-bottom: 10px;
}
.landing_content form button.button__connexion {
  width: 100%;
  background: white;
  margin-top: 30px;
}
.landing_content.scoringLanding {
  justify-content: center;
}

.landing_content.animationEnded form.scoringAuth {
  opacity: 1;
  transform: translateY(0);
}
.fade-top {
  opacity: 1;
  transition: 400ms;
  transform: translateY(0);
}
.fade-top.appear {
  opacity: 0;
  transform: translateY(100px);
}
.fade-top.appear-done {
  opacity: 1;
  transform: translateY(0);
}
.fade-top.exit {
  opacity: 0;
  transform: translateY(100px);
}

.backgroundSVGAnimation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
  object-position: right;
  transition: background 1s ease-in-out;
}
.quote {
  position: absolute;
  left: 0;
  color: white;
  justify-content: center;
  align-items: flex-end;
}
.quote h3 {
  font-size: 44px;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: 700;
  margin: 0 5vw 20px;
}

.quote span {
  margin: 0 5vw;
}

.landingPage header.header__home {
  background: none;
  height: 60px;
  width: 100vw;
  display: block;
}
.landingPage .home__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.landingPage .header__logo {
  width: 4.5vw;
  height: 40px;
  margin-left: 7vw;
  object-fit: contain;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .landingPage {
    background: linear-gradient(135deg, #000428, #004480);
  }

  .landingPage .header__logo {
    width: auto;
    margin-top: 10px;
    margin-left: 4vw;
  }
}

.landingPage .nav__connexion {
  font-family: 'Circular', sans-serif;
  outline: none;
  background-color: white;
  border: solid 2px;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  height: 4vh;
  color: #575757;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
}

.landingPage .buttonAnimation {
  position: absolute;
}
.landing_score_wrapper > div {
  width: 55%;
  padding: 0 20px 0 0;
}

.landing_score_sharing ._link a {
  margin: 10px;
}
.landing_content.scoreEnd {
  justify-content: space-between !important;
}
.landing_score_wrapper h2 {
  font-size: 22px;
  margin-bottom: 20px;
}
.landing_score_wrapper {
  display: flex;
  margin: 0px 50px;
  height: calc(100% - 358px);
  align-items: center;
  color: white;
  margin-top: 20px;
  height: 100%;
}
.landing_score_wrapper > img {
  width: 45%;
  box-shadow: #78787875 0 0 15px 0;
  border-radius: 5px;
  max-height: 70%;
  object-fit: cover;
}
.landing_score_sharing {
  margin: 50px;
}
.landing_score_sharing ._link {
  display: flex;
  justify-content: center;
}
.landing_score_sharing ._link img {
  height: 50px;
  width: 50px;
}

.scoringLanding .modalBack {
  color: black;
}
.scoringLanding .modalFin h2 {
  color: black;
}
.scoringLanding .modalFin {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: white;
  /* color: black !important; */
}
.scoringLanding label,
.scoringLanding input {
  color: black;
}
.scoringLanding .mauticform-row textarea {
  box-shadow: #6363633a 0 0 10px;
}

.scoringLanding button.mauticform-button {
  color: black;
  background: rgb(161 161 161 / 55%);
  padding: 10px 20px;
}

div#mauticform_feedbackformation_submit {
  align-items: center;
}
.scoringLanding form {
  max-width: 550px;
}
.scoringLanding .modalBack {
  color: black;
  top: 0;
  right: 0;
}
.scoringLanding .modalFin h2 {
  color: black;
  font-size: 28px;
  font-weight: 500;
}
.scoringLanding .modalContentFormation {
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  overflow: scroll;
  margin: 40px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
div#mauticform_wrapper_feedbackformation {
  margin: auto 0;
}

.modalFin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 111;
  margin: 0;
  height: 100vh;
  background: #000000a3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modalContentFormation h2 {
  margin: 0 0 20px;
}
.modalBack {
  color: white;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.modalContentFormation {
  width: 550px;
  height: 500px;
  padding: 20px;
  margin: auto;
  position: relative;
  color: white;
  overflow: scroll;
  border-radius: 5px;
}
button.mauticform-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  border: none;
  background: #ffffff30;
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.mauticform-row textarea {
  height: 40px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px;
  border-radius: 3px;
}
.mauticform-row label {
  margin-bottom: 10px;
}
.mauticform-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 25px;
}
.mauticform-row input {
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  height: 24px;
}
.backPlayer span {
  margin-left: 40px;
  cursor: pointer;
}
.backPlayer {
  width: 100%;
  position: absolute;
  height: 7.5vh;
  z-index: 11;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 600px) {
  .landing_content {
    margin-top: 0;
    /*height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;*/
    height : calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
    overflow: scroll;
    min-height: unset;
    padding: 0 7vw;
    width: calc(100vw - 14vw);
    justify-content: flex-start;
  }
  .landing_content .landing_title_wrapper {
    height: 80px;
    margin-top: 40px !important;
  }
  .landing_content h1.landing_title {
    font-size: 35px;
  }
  .step0Done .jeuBoxIntro .buttonJeu {
    position: relative;
    left: -50%;
    margin-bottom: 20px;
  }
  .landing_content h1.landing_title.background {
    font-size: 75px;
    margin-top: 0;
    height: 80px;
  }

  .scoringLanding .modalContentFormation {
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .landing_score_wrapper h2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .landing_score_wrapper > div {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .landing_score_wrapper {
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .landing_score_wrapper > img {
    width: 100%;
    max-height: 27vh;
    padding: 20px 0;
    box-shadow: none;
    height: fit-content;
  }
  .landing_score_sharing {
    padding: 30px 0;
    margin: 0;
    text-align: center;
  }
  .scoringLanding .modalFin {
    margin: 0 7vw;
    width: calc(100vw - 14vw);
  }
  .buttonValidate {
    margin-left: 8px;
  }
  button.buttonValidate.previous {
    margin-right: 8px;
    margin-left: 0px;
  }
  .landing_score_sharing ._link {
    transform: scale(0.6);
  }
  .landing_score_sharing h3 {
    font-size: 15px;
    font-weight: 300;
  }
  .landing_content.scoringLanding h1.landing_title {
    font-size: 25px;
  }
  .hideMobile {
    display: none;
  }
}
