.homepage html,
.homepage button {
  font-family: 'Circular', sans-serif;
  font-weight: normal;
  color: #142946;
}

.homepage button {
  outline: none;
}

.homepage button:hover {
  cursor: pointer;
}

.homepage .header__home {
  width: 100vw;
  height: 86vh;
  background-image: url('https://video.thelab.risinguparis.com/img/background_tower.png');
  background-repeat: no-repeat;
  background-size: 100vw 86vh;
}

.homepage .home__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.homepage .header__logo {
  width: 4.5vw;
  height: 6vh;
  margin-left: 7vw;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translateY(-1vh);
  -ms-transform: translateY(-1vh);
  transform: translateY(-1vh);
  margin-top: 3vh;
}

.homepage .nav__href {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 4vw;
  font-weight: lighter;
  max-width: 100%;
}

.homepage .href {
  color: white;
  text-decoration: none;
  margin-left: 6vw;
  margin-top: 4vh;
  font-size: 16px;
}

.homepage .nav__connexion {
  width: 13vw;
  height: 4vh;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-top: 3vh;
  margin-right: 7vw;
  background-color: white;
  border: solid 2px;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

.homepage .language {
  border: none;
  background-color: rgba(
    255,
    255,
    255,
    0
  ); /* Problème avec Safari lorsque l'on initialise background-color: transparent; solution rgba ne marche pas */
  color: white;
  z-index: 5;
  /* margin-left: 2vw; */
  margin-top: 1vh;
}

.homepage .home__brain {
  margin-left: 25vw;
  margin-top: -3vh;
}

.homepage .underline--solutions {
  position: absolute;
  top: 6.5vh;
  width: 2vw;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}

.homepage .scroll__incitement {
  position: absolute;
  width: 1.2vw;
  top: 75.5vh;
  left: 48.9vw;
  z-index: 4;
}

.homepage .header__content {
  position: absolute;
  width: 100vw;
  height: 91vh;
  top: 9vh;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: white;
  text-align: center;
}

.homepage .content__title {
  position: relative;
  font-size: 48px;
  width: 40vw;
  margin-top: 21vh;
  margin-left: 30vw;
  font-weight: bold;
  letter-spacing: 4px;
}

.homepage .content__text {
  text-align: center;
  font-size: 18px;
  line-height: 2rem;
  font-weight: lighter;
  margin-top: 5vh;
  width: 55vw;
  margin-left: 22.5vw;
}

.homepage .hook {
  height: 60vh;
  width: 100vw;
}

.homepage .hook__title {
  font-size: 33px;
  font-weight: bolder;
  margin-top: 15vh;
  text-align: center;
  color: #142946;
}

.homepage .title__important {
  color: #5ba1e9;
}

.homepage .hook__content {
  margin-top: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.homepage .hook__exp {
  text-align: center;
  width: 35vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.homepage .hook__exp--none {
  text-align: center;
  width: 35vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5vw;
}

.homepage .exp__title {
  font-size: 28px;
  font-weight: bold;
}

.homepage .exp__text {
  font-size: 18px;
  font-weight: lighter;
  line-height: 2rem;
  color: #8e8b8b;
  margin-top: 5vh;
}

.homepage .exp__button {
  font-size: 12px;
  font-weight: bolder;
  height: 6vh;
  width: 20vw;
  border: solid 2px;
  background-color: white;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  margin-top: 4vh;
}

.homepage .hook__logo {
  width: 12vw;
  position: absolute;
  left: 44vw;
  top: 115vh;
}

.homepage .flexibility {
  width: 100vw;
  height: 38vh;
  background-image: url('https://video.thelab.risinguparis.com/img/background_flexibility.png');
  background-repeat: no-repeat;
  background-size: 100vw 38vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.homepage .flexibility__title {
  font-size: 45px;
  font-weight: bolder;
  line-height: 6rem;
  width: 70vw;
  text-align: center;
  margin: auto;
  color: #152a45;
}

.homepage .presentiel {
  width: 100vw;
  height: 80vh;
  margin-top: 14vh;
}

.homepage .presentiel__hook {
  font-size: 16px;
  font-weight: lighter;
  color: #787878;
  margin-left: 7vw;
  margin-top: 2vh;
}

.homepage .presentiel__title {
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 1rem;
  margin-left: 7vw;
  margin-top: 3vh;
  color: #26385b;
}

.homepage .presentiel__content {
  /* height: 50vh; */
  width: 47vw;
  margin-left: 7vw;
  margin-top: 6vh;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 1px 20px #eaeaea;
  box-shadow: 0px 1px 20px #eaeaea;
  overflow: hidden;
}

.homepage .presentiel__text {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.7rem;
  width: 37vw;
  margin-left: 3vw;
  padding-top: 5vh;
  color: #26385b;
}

.homepage .presentiel__keywords {
  width: 30vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.homepage .presentiel__keyword {
  color: #787878;
  font-size: 14px;
  font-weight: lighter;
  margin-left: 3vw;
  margin-top: 5vh;
}

.homepage .presentiel__button {
  margin-left: 3vw;
  margin-top: 6vh;
  width: 11vw;
  height: 4vh;
  background-color: #26385b;
  font-size: 12px;
  font-weight: bolder;
  color: white;
  border: none;
  margin-bottom: 5vh;
  border-radius: 2px;
}

.homepage .presentiel__picture {
  height: 70vh;
  position: absolute;
  right: 0;
  top: 216vh;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 1;
}

.homepage .digital {
  width: 100vw;
  height: 80vh;
}

.homepage .digital__hook {
  font-size: 16px;
  text-align: right;
  font-weight: lighter;
  color: #787878;
  margin-right: 7vw;
  margin-top: 10vh;
}

.homepage .digital__title {
  text-align: right;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 1rem;
  margin-right: 5.7vw;
  margin-top: 3vh;
  color: #26385b;
}

.homepage .digital__content {
  width: 48vw;
  margin-left: 45.5vw;
  margin-top: 8vh;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 1px 20px #eaeaea;
  box-shadow: 0px 1px 20px #eaeaea;
  overflow: hidden;
}

.homepage .digital__text {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 2.7rem;
  width: 40vw;
  margin-left: 2vw;
  padding-top: 5vh;
  color: #26385b;
}

.homepage .digital__keywords {
  width: 30vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 12vw;
}

.homepage .digital__keyword {
  color: #787878;
  font-size: 14px;
  font-weight: lighter;
  margin-left: 3vw;
  margin-top: 5vh;
}

.homepage .digital__button {
  width: 11vw;
  height: 4vh;
  background-color: #26385b;
  font-size: 12px;
  font-weight: bolder;
  color: white;
  border: none;
  border-radius: 2px;
  float: right;
  margin-top: 9vh;
  margin-bottom: 5vh;
  margin-right: 6vw;
}

.homepage .digital__picture {
  height: 82vh;
  width: 44vw;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  /* left: 4vw; */
  top: 308vh;
  z-index: 1;
}

.homepage .slider__puces--home {
  margin-top: 10vh;
  margin-left: 1.5vw;
  width: 15vw;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
}

.homepage .home__start {
  width: 100vw;
  height: 20vh;
  margin-top: 10vh;
  background-color: #152a45;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 1px 10px #eaeaea;
  box-shadow: 0px 1px 10px #eaeaea;
}

.homepage .start__button {
  margin: auto;
  width: 23vw;
  height: 7vh;
  font-size: 14px;
  font-weight: bolder;
  background-color: white;
  border: solid 2px;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  color: #575757;
}

.homepage .home__customer {
  width: 100vw;
  height: 50vh;
}

.homepage .home__customer--nsh {
  width: 100vw;
  height: 90vh;
}

.homepage .customer__title {
  font-size: 35px;
  font-weight: bolder;
  width: 50vw;
  text-align: center;
  padding-top: 50px;
  margin-left: 25vw;
  margin-bottom: 20px;
  word-spacing: 0.2em;
  line-height: 2em;
  color: #101d35;
}
.homepage .home__customer--digital {
  margin-bottom: 50px;
}

.homepage .tech-slideshow {
  will-change: transform;
  height: 200px;
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.homepage .tech-slideshow--bw {
  will-change: transform;
  height: 200px;
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.homepage .tech-slideshow > div {
  height: 200px;
  width: 3920px;
  background: url('https://video.thelab.risinguparis.com/img/logo_partener.png');
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
}

.homepage .tech-slideshow--bw > div {
  height: 200px;
  width: 3920px;
  background: url('https://video.thelab.risinguparis.com/img/logo_partener.png');
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
}

.homepage .tech-slideshow .mover-1 {
  -webkit-animation: moveSlideshow 40s linear infinite;
  animation: moveSlideshow 40s linear infinite;
}

.homepage .tech-slideshow--bw .mover-1 {
  -webkit-animation: moveSlideshow 40s linear infinite;
  animation: moveSlideshow 40s linear infinite;
}

@-webkit-keyframes moveSlideshow {
  100% {
    -webkit-transform: translateX(-66.6666%);
    transform: translateX(-66.6666%);
  }
}

@keyframes moveSlideshow {
  100% {
    -webkit-transform: translateX(-66.6666%);
    transform: translateX(-66.6666%);
  }
}

.homepage .home__contact {
  width: 100vw;
  height: 37vh;
  background-image: url('https://video.thelab.risinguparis.com/img/background_contact.png');
  background-size: 100vw 37vh;
  margin-top: 5vh;
}

.homepage .contact__title {
  font-size: 34px;
  font-weight: bolder;
  color: white;
  text-align: center;
  padding-top: 13vh;
  word-spacing: 0.1em;
}

.homepage .contact__boutton {
  margin-top: 6vh;
  font-size: 18px;
  font-weight: bold;
  color: #575757;
  width: 17vw;
  height: 5vh;
  border: solid 2px;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  margin-left: 41.5vw;
}

.homepage div#solutionsNav a:first-child {
  padding-bottom: 10px;
  border-bottom: solid #152a45 0.5px;
}

.homepage div#solutionsNav a:last-child {
  padding-top: 10px;
}

.homepage .menuNav:hover #solutionsNav {
  height: auto;
  opacity: 1;
}
.homepage .solutionNav {
  text-decoration: none;
  color: white;
}
.homepage div#solutionsNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 9vh;
  padding: 12px;
  color: #152a45;
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  height: 0px;
  z-index: 100;
  opacity: 0;
  background: white;
}

.homepage div#solutionsNav .solutionNav {
  color: #152a45;
}
.homepage .footer__ask {
  background-image: url('https://video.thelab.risinguparis.com/img/image-footer.png');
  background-size: 100vw 220px;
  width: 100vw;
  height: 220px;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.homepage .ask__text {
  font-family: 'Circular', sans-serif;
  font-weight: bolder;
  font-size: 24px;
  line-height: 60px;
  color: #ffffff;
}
.homepage .footer__button {
  background-color: rgb(255, 255, 255, 40%);
  height: 33.7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.2vh 2vw;
  cursor: pointer;
}
.homepage .button__text {
  font-family: 'Circular', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 0.44px;
  color: #ffffff;
}
.homepage .footer__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40vw;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.homepage .articles__footer {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 4vh;
  background-image: url('https://video.thelab.risinguparis.com/img/background-bleu.png');
  background-size: 100vw 28vh;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
}

.homepage .footer__logo {
  width: 4vw;
  height: 5.75vh;
  margin-left: 5.5vw;
  -o-object-fit: contain;
  object-fit: contain;
  margin-top: 4vh;
}

.homepage .footer__risingUp__text {
  font-size: 10px;
  font-weight: lighter;
  color: white;
  width: 17vw;
  line-height: 2em;
  margin-left: 5.5vw;
  margin-top: 20px;
}

.homepage .footer__solutions {
  margin-top: 4vh;
  margin-left: 3vw;
}

.homepage .footer__solution--bold {
  font-size: 12px;
  font-weight: bold;
}

.homepage .footer__solution--light {
  font-size: 12px;
  font-weight: lighter;
  margin-top: 20px;
}

.homepage .footer__ressources {
  margin-top: 4vh;
  margin-left: 8vw;
}

.homepage .footer__newsletter {
  margin-top: 4vh;
  margin-left: 7vw;
  width: 18vw;
}
.homepage .newsletter__trait {
  margin-left: 5vw;
  width: 90vw !important;
  height: 1px;
  background-color: #ffffff;
  margin-top: 4vh;
  display: none !important;
}

.homepage .footer__input {
  width: 16vw;
  height: 40px;
  margin-top: 5px;
  border: none;
  border-radius: 1px;
  text-indent: 0.2vw;
  float: left;
  width: 80%;
}

.homepage .footer__input__button {
  font-size: 12px;
  font-weight: lighter;
  color: white;
  background-color: #8e8b8b;
  width: 40px;
  height: 42px;
  border: none;
  border-radius: 1px;
  position: absolute;
  margin-top: 5px;
}

.homepage .footer__socials {
  width: 13vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 85px;
}

.homepage .footer__social {
  width: 2vw;
}

.homepage .footer__social--facebook {
  width: 2.1vw;
}

.homepage .footer__datas {
  margin-top: 4vh;
  margin-left: 5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.homepage .footer__data__picture {
  width: 10vw;
}

.homepage .footer__data__text {
  font-size: 9px;
  font-weight: lighter;
  margin-top: 3vh;
  margin-left: 0px;
}

.homepage .footer__solution--bold--new {
  font-size: 12px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.homepage .footer__follow {
  display: none;
}
.homepage .footer__solutions a,
.homepage .footer__ressources a,
.homepage .footer__socials a {
  color: white;
  text-decoration: none;
}

.homepage #language {
  display: none;
}

.homepage div#modal,
.homepage div#prix {
  position: relative;

  top: 0;
  overflow: scroll;

  opacity: 0;
  background: white;

  width: 86vw;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9vh 7vw;
}

.homepage img.modal__logo {
  position: absolute;
  width: 4.5vw;
  height: 6vh;
  margin-left: 7vw;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translateY(-1vh);
  -ms-transform: translateY(-1vh);
  transform: translateY(-1vh);
  margin-top: 3vh;
  top: 0;
  left: 0;
}

.homepage span#closeModal {
  position: absolute;
  top: 0;
  margin-right: 7vw;
  margin-top: 3vh;
  right: 0;
  font-size: 22px;
}

.homepage div#formTitle {
  width: 48%;
}

.homepage #modal div#form,
.homepage #prix div#form {
  width: 38%;
  padding: 30px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(158, 158, 158, 0.3) 0 0 10px 0;
  box-shadow: rgba(158, 158, 158, 0.3) 0 0 10px 0;
}

.homepage #formTitle h2 {
  font-size: 33px;
  margin-bottom: 30px;
}

.homepage #formTitle h3 {
  font-size: 19px;
  margin-bottom: 40px;
}
.homepage #formTitle a {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.homepage h3 {
  font-size: 24px;
}

.homepage div#mauticform_wrapper_demandededevis {
  margin: 30px 0 0 0;
}

.homepage #modal form label,
.homepage #prix form label {
  font-size: 12px !important;
  font-weight: normal;
}

.homepage #modal form textarea,
.homepage #prix form textarea {
  /* background: #efefef; */
  height: 90px;
}

.homepage #modal button#mauticform_input_demandededevis_submit,
.homepage #prix button#mauticform_input_demandededevis_submit,
.homepage #mauticform_input_thelabcommingsoon_submit {
  background-color: white;
  border: solid 2px;
  border-image-source: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  border-image-slice: 1;
  color: #575757;
}

.homepage #modal.active,
.homepage #prix.active {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
}

.homepage .underline--solutions.none,
.homepage .isHover .underline--solutions {
  width: 0px;
}
.homepage .menuNav.hover .underline--solutions {
  width: 2vw;
}

.homepage #burgerMenu {
  display: none;
}

.homepage .thelabColor {
  background: -o-linear-gradient(
    130deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  background: linear-gradient(
    320deg,
    rgb(251, 18, 190),
    rgb(251, 18, 190),
    rgb(96, 157, 229),
    rgb(51, 249, 240),
    rgb(176, 220, 122),
    rgb(253, 216, 106),
    rgb(253, 216, 106)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 600% 600%;
  -webkit-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 30% 0%;
  }
  50% {
    background-position: 71% 100%;
  }
  100% {
    background-position: 30% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 30% 0%;
  }
  50% {
    background-position: 71% 100%;
  }
  100% {
    background-position: 30% 0%;
  }
}

div#cookies-eu-banner {
  background-color: #fff;
  position: fixed !important;
  padding: 2em;
  bottom: 1em;
  height: auto;
  width: 94%;
  overflow: hidden;
  left: 3%;
  z-index: 99999999;
  -webkit-box-shadow: 0 0 10px #505050c7;
  box-shadow: 0 0 10px #505050c7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
}
@media (min-width: 1000px) {
  div#cookies-eu-banner {
    width: 1000px;
    left: 50%;
    margin-left: -500px;
  }
}
@media (min-width: 768px) {
  div#cookies-eu-banner {
    -webkit-box-shadow: 0 0 10px #505050c7;
    box-shadow: 0 0 10px #505050c7;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
  }
}

#cookies-eu-banner div {
  width: 100%;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
}

#cookies-eu-banner p {
  font-size: 14px;
  width: 75%;
  text-align: justify;
  font-weight: 400;
}

#cookies-eu-banner a {
  color: rgba(25, 23, 17, 0.8);
}

button#cookies-eu-accept {
  background: rgb(16, 42, 71);
  border: none;
  color: white;
  padding: 10px 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
