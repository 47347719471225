/* SCROLLBAR */
.player ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
.player ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
.player ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.player ::-webkit-scrollbar-corner {
  background-color: transparent;
}
